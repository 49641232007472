<template>
  <div v-if="selectedUserComments?.length > 0" class="w-full">
    <div v-for="comment in selectedUserComments" :key="comment.id" class="pt-3 mt-3 border-t">
      <UserComment :comment="comment" :commentable="commentable" :depth="1" :discussionComment="comment" :commentableType="commentableType" />
    </div>
  </div>
  <div v-else>
    <h2 class="text-lg font-semibold mt-4">Be the first to comment!</h2>
    <div class="mt-1 text-sm">Nobody's responded to this post yet.</div>
    <div class="text-sm">Add your thoughts and get the conversation going.</div>
  </div>

  <div class="mt-4">
    <div v-if="!hasCompletedAccountSetup" class="relative">
      <div class="absolute left-0 right-0 z-50 m-auto text-center bg-white border border-gray-500 shadow-sm top-4 rounded-xl p-9 w-fit">
        <div class="text-xl font-bold text-secondary">Verify Your Account</div>
        <div class="mt-2 mb-4 text-gray-500">To get full access</div>
        <Button class="p-button-secondary" @click="$router.push('/account-setup')">Verify Account</Button>
      </div>
      <div v-for="comment in 3" :key="comment" class="pt-3 mt-3 border-t">
        <div class="flex flex-row items-center">
          <div class="relative z-20 object-cover w-10 h-10 bg-gray-100 border-4 border-white border-solid rounded-full" />
          <span class="w-16 h-5 pl-1 font-semibold bg-gray-100 rounded-md"></span>
        </div>
        <div class="w-full pt-1 pl-10">
          <div class="w-full h-5 font-semibold bg-gray-100 rounded-md"></div>
          <div class="w-2/3 h-5 mt-2 font-semibold bg-gray-100 rounded-md"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full pt-4 mt-4 border-t relative">
    <div class="w-full relative">
      <Textarea v-model="comment" :autoResize="true" rows="3" cols="30" required maxLength="65535" placeholder="Add a comment. Please do not comment Patient Information" id="commentTextarea" @keydown.enter="handleKeyDown" />
      <div class="absolute bottom-2 right-2">
        <span class="hidden sm:block text-xs text-gray-500">{{ isMac ? '⌘' : 'CTRL' }} + ↵ to submit</span>
      </div>
    </div>

    <div class="flex justify-end mt-2">
      <Button label="Submit" class="p-button-secondary" @click="submitComment" :loading="isLoadingComment" :disabled="isLoadingComment" id="commentButton" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserComment from './UserComment.vue';

export default {
  name: 'CommentsSection',
  components: {
    UserComment,
  },
  props: {
    commentable: {
      type: Object,
      required: true,
    },
    commentableType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comment: null,
      isLoadingComment: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'selectedUserComments']),
    hasCompletedAccountSetup() {
      return this.loggedInUser?.role && this.loggedInUser?.first_name && this.loggedInUser?.last_name && (this.loggedInUser?.role !== 'cme' || (this.loggedInUser?.profession && this.loggedInUser?.address.province));
    },
  },
  methods: {
    ...mapActions(['createUserComment', 'getUserComments']),
    handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        event.preventDefault();
        this.submitComment();
      }
    },
    submitComment() {
      if (!this.comment) {
        return;
      }

      if (!this.hasCompletedAccountSetup) {
        this.$emit('needsAccountSetup');
        return;
      }

      this.isLoadingComment = true;
      this.createUserComment({
        commentable: this.commentable,
        commentable_id: this.commentable.id,
        commentable_type: this.commentableType,
        text: this.comment,
      })
        .then(() => {
          this.comment = null;
          this.isLoadingComment = false;
          this.loadComments();
        })
        .catch(() => {
          this.isLoadingComment = false;
          this.showGenericErrorToast();
        });
    },
    loadComments() {
      this.getUserComments({
        commentable_id: this.commentable.id,
        commentable_type: this.commentableType,
      }).catch(() => {
        this.showGenericErrorToast();
      });
    },
  },
  created() {
    if (this.loggedInUser) {
      this.loadComments();
    }
  },
};
</script>
