<template>
  <template v-if="tourSteps.length > 0">
    <v-tour name="siteTour" :steps="tourSteps" :callbacks="callBacks" :options="{ highlight: true }">
      <template v-slot="tour">
        <transition name="fade">
          <v-step style="background-color: var(--vh-success-green)" class="!text-white !shadow !font-bold !text-left !rounded-md !px-4 !pt-4" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip" :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
            <template #actions>
              <div class="flex flex-row w-full !mt-6">
                <div @click="tour.skip" id="tour-end" class="border-white mr-2 hover:cursor-pointer !text-sm !font-bold !text-white justify-start flex w-full">End Tour</div>
                <div v-if="tour.currentStep == tourSteps.length - 1" id="tour-finish" @click="finish" class="!text-sm hover:cursor-pointer !font-bold !text-white justify-end flex w-full">Finish</div>
                <div v-else id="tour-next" @click="tour.nextStep" class="!text-sm hover:cursor-pointer !font-bold !text-white justify-end flex w-full">Next Step</div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </template>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { TourSteps } from '@/components/tour/tourSteps.js';

export default {
  data() {
    return {
      tourSteps: [],
      callBacks: {
        onSkip: this.onSkip,
      },
    };
  },
  computed: {
    ...mapGetters(['activeTour', 'loggedInUser', 'hasProvinceOfPractice', 'isActive']),
  },
  methods: {
    ...mapMutations(['setActiveTour', 'updateActiveTourStep', 'setShowTheSideBar']),
    onSkip() {
      window.location.reload();
    },
    finish() {
      window.location.reload();
    },
    // use this function to load in the book consult tour steps
    // this is because a lot of provinces and states start without select specialty
    // IF SO, we just remove the element of the book consults steps that has select specialty
    // (this is index 1)
    loadBookConsultTour(tour) {
      // make sure we offer consult services there...
      if (this.hasProvinceOfPractice(this.loggedInUser, ['NS', 'ON', 'AB', 'NL', 'PE', 'TX'])) {
        if (this.consultBookingView(this.loggedInUser) == 'default') {
          this.tourSteps.push(...tour.steps.BOOK_PHONE_CONSULT);
        } else {
          this.tourSteps.push(...tour.steps.BOOK_PHONE_CONSULT.filter((_, index) => index !== 1));
        }
      }
    },
  },
  created() {
    let tour = new TourSteps(this.$router);
    if (this.loggedInUser.role == 'cme') {
      this.tourSteps.push(...tour.steps.VIEW_CME_LECTURES);
    } else if (this.loggedInUser.role == 'specialist') {
      this.tourSteps.push(...tour.steps.SET_AVAILABILITY);
      if (this.isActive(this.loggedInUser)) {
        this.loadBookConsultTour(tour);
      }
      this.tourSteps.push(...tour.steps.VIEW_REFERRALS_TABLE);
      this.tourSteps.push(...tour.steps.VIEW_CME_LECTURES);
      this.tourSteps.push(...tour.steps.VIEW_RESOURCES);
      this.tourSteps.push(...tour.steps.CREATE_NOTES);
      this.tourSteps.push(...tour.steps.VIEW_BILLING);
    } else {
      // pcp
      this.loadBookConsultTour(tour);
      this.tourSteps.push(...tour.steps.VIEW_REFERRALS_TABLE);
      this.tourSteps.push(...tour.steps.VIEW_CME_LECTURES);
      this.tourSteps.push(...tour.steps.VIEW_RESOURCES);
      this.tourSteps.push(...tour.steps.CREATE_NOTES);
      this.tourSteps.push(...tour.steps.VIEW_BILLING);
    }
  },
  watch: {
    'activeTour.step'() {
      // I've set it up so the tour steps are named after the ids of the components they target
      // this way a whole bunch of if statements mapping step and target are reduced to this one line
      let target = document.getElementById(this.activeTour.step);

      if (target != null) {
        let rect = target.getBoundingClientRect();
        let el = document.getElementById('tour-teleport-target');
        el.style.position = 'absolute';
        el.style.top = `${rect.top - 180}px`;
        el.style.left = `${rect.left + window.scrollX - 75}px`;
        el.style.width = `${rect.width}px`;
        el.style.height = `${rect.height}px`;
        el.style.display = 'block';

        // some step specific styling...
        if (this.activeTour.step == 'tour_select_specialist') {
          el.style.top = `${rect.top + 80}px`;
        }
        if (this.activeTour.step == 'referralHistoryList') {
          el.style.width = '80%';
          el.style.left = `${rect.left + window.scrollX}px`;
        }
        if (this.activeTour.step == 'lectures') {
          el.style.top = `${rect.top}px`;
        }
        target.style.visibility = 'hidden';
      }
    },
  },
};
</script>
