<template>
  <Dialog v-model:visible="visible" :modal="true" :header="fileName" :style="{ width: '80vw', height: '90vh' }" :maximizable="true" :dismissable-mask="true" @hide="closeDialog" id="fileViewerDialog">
    <div class="file-viewer-container">
      <iframe v-if="isPDF" :src="fileUrl" :type="fileType" class="file-viewer-object" frameborder="0" allowfullscreen>
        <p>Unable to display file. <a :href="fileUrl" download>Download</a> instead.</p>
      </iframe>
      <img v-else-if="isImage" :src="fileUrl" :alt="fileName" class="image-viewer border shadow-sm" />
      <div v-else class="flex items-center justify-center h-full">
        <p>Unable to display file. <a :href="fileUrl" download>Download</a> instead.</p>
      </div>
    </div>
    <template #footer>
      <Button label="Download" icon="pi pi-download" class="p-button-sm p-button-secondary" @click="downloadFile" id="file-viewer-download-button" />
      <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-sm p-button-secondary p-button-outlined" id="file-viewer-close-button" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
export default {
  name: 'FileViewerDialog',
  components: {
    Dialog,
    Button,
  },
  props: ['show', 'fileViewerData'],
  data() {
    return {
      fileUrl: null,
      visible: false,
      fileType: null,
      fileContent: null,
      fileName: '',
    };
  },
  emits: ['fileViewerDialogClosed'],
  methods: {
    getFileType() {
      const extension = this.fileName.split('.').pop().toLowerCase();
      const mimeTypes = {
        pdf: 'application/pdf',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        svg: 'image/svg+xml',
      };
      return mimeTypes[extension] || 'application/octet-stream';
    },
    closeDialog() {
      if (this.fileUrl) {
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = null;
      }
      this.$store.commit('resetFileViewerData');
      this.$emit('fileViewerDialogClosed');
    },
    downloadFile() {
      const fileLink = document.createElement('a');
      fileLink.href = this.fileUrl;
      fileLink.setAttribute('download', this.fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
  },
  created() {
    this.fileContent = this.fileViewerData.content;
    this.fileName = this.fileViewerData.fileName;
    // Clean up old URL if it exists
    if (this.fileUrl) {
      window.URL.revokeObjectURL(this.fileUrl);
    }
    // Set the correct MIME type based on file extension
    this.fileType = this.getFileType();
    // Create new URL with the correct type
    const blob = new Blob([this.fileContent], { type: this.fileType });
    this.fileUrl = window.URL.createObjectURL(blob);
    this.visible = this.show;
  },
  beforeUnmount() {
    if (this.fileUrl) {
      window.URL.revokeObjectURL(this.fileUrl);
    }
  },
  computed: {
    isPDF() {
      return this.fileType === 'application/pdf';
    },
    isImage() {
      return this.fileType?.startsWith('image/');
    },
  },
};
</script>

<style scoped>
.file-viewer-container {
  height: calc(90vh - 120px); /* Account for header and footer */
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-viewer-object {
  width: 100%;
  height: 100%;
  border: none;
}

.image-viewer {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

:deep(.p-dialog-content) {
  height: 100%;
  padding: 0;
  overflow: hidden;
}
</style>
