<template>
  <section v-if="!loggedInUser" class="text-white bg-secondary">
    <div class="px-6 py-12 text-left md:px-12">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="grid items-center gap-12 lg:grid-cols-2">
          <div class="mt-0 mb-4" :class="{ 'pt-12': this.isNativePlatform() && isCme(loggedInUser) }">
            <h4 class="mb-4 text-xs font-normal tracking-widest uppercase">The Ben Cookey CME webinars:</h4>
            <h1 class="mb-2 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Watch, earn and learn through free CME webinars</h1>
            <p class="mb-8">Gain CME credits and knowledge from specialists on Virtual Hallway</p>
            <div class="flex flex-row w-full">
              <div class="w-full mr-0 md:w-52 md:mr-8">
                <Button @click="showRegistration = true" class="w-full" style="display: block">Watch Now</Button>
              </div>
              <div class="hidden w-52 md:block">
                <Button @click="this.$refs.interestedInPresentingLectureDialog.openDialog()" class="w-full" style="display: block">Want to be a speaker?</Button>
              </div>
            </div>
          </div>

          <div class="hidden mb-12 lg:mb-0 lg:block">
            <transition name="slide-up-fade" appear>
              <div v-if="showRegistration" class="max-w-lg p-4 text-black bg-white rounded-2xl animated fade-in-up sm:p-10">
                <RegistrationForm :loggedInUser="loggedInUser" :background_role="'cme'" :customHeadline="'Lecture Registration'" :customButtonLabel="'Register'" />
              </div>
            </transition>
            <img v-if="!showRegistration" src="@/assets/Registration-BG-3.webp" class="w-full mb-2 shadow-lg image-alt-rounded-corners" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="loggedInUser" class="text-white bg-secondary">
    <div class="px-4 pb-5 text-left md:px-12" :class="isNativePlatform() ? 'pt-32' : 'pt-24'">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="flex flex-row gap-8">
          <div class="mt-0 mb-4">
            <h4 class="mb-4 text-xs font-normal tracking-widest uppercase">The Ben Cookey CME webinars:</h4>
            <h1 class="mb-2 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Watch, earn and learn through free CME webinars</h1>
            <h1 class="text-sm">The Dr. Ben Cookey lectures are regular web-based lectures by experts on a variety of clinical topics. Dr. Cookey was known for his excellent rapport with colleagues, and was always willing to provide input on difficult cases. Some would say he was a master of the "Hallway Consult". Lectures with a <i class="mx-1 pi fas fa-award" style="font-size: 0.8rem" /> icon provide you with a CME credit, and supporting documentation to download and submit. <TutorialVideoDialog ref="tutorialVid" video="cme_lectures_credits" linkLabel="Click here to watch how this works." /></h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="lecture_menu_bar" v-if="loggedInUser" class="flex flex-col items-center w-full bg-white md:px-4" style="border-bottom: 1px solid #c9c9c9">
    <div class="flex items-center justify-start w-full" style="max-width: 1120px">
      <Menubar :model="lecture_menu_items" class="w-full md:-ml-4">
        <template #item="{ item, props }">
          <a v-ripple class="flex mx-1 text-white rounded align-items-center" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </template>
      </Menubar>
      <div class="flex items-center justify-center h-full">
        <Button id="lectures-filter-button" icon="pi pi-filter" class="p-button-rounded p-button-text" label="Filter" @click="toggleFilterOverlay($event)" aria-haspopup="true" aria-controls="filter-overlay" />
      </div>
      <OverlayPanel ref="filterOverlay" id="filter-overlay" :showCloseIcon="false" class="filter-overlay">
        <div class="px-3 pt-3 pb-1" style="min-width: 280px">
          <div class="w-full mb-2">
            <Dropdown placeholder="Filter by Presenter" id="lecture_presenter_filter_dropdown" :options="allPresenterNames" v-model="selectedLecturePresenter" :showClear="true" @change="filterLecturesByPresenter" class="w-full p-inputtext-sm" style="width: 100%" />
          </div>
          <div class="w-full mb-2">
            <Dropdown placeholder="Filter by Specialty" id="lecture_filter_dropdown" :options="allPresenterSpecialties" v-model="selectedLectureSpecialty" :showClear="true" @change="filterLecturesBySpecialty" class="w-full p-inputtext-sm" style="width: 100%" />
          </div>
        </div>
      </OverlayPanel>
    </div>
  </section>
  <section id="lecture_search_bar" v-if="loggedInUser" class="flex flex-col items-center w-full bg-white md:px-4" style="border-bottom: 1px solid #c9c9c9">
    <div class="flex flex-col items-start justify-start w-full px-2 py-2 md:flex-row md:items-center" style="max-width: 1120px">
      <IconField iconPosition="left" class="w-full">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText v-model="filterValue" placeholder="Search Lectures" class="w-full p-inputtext-sm" style="padding-left: 2.5rem; width: 100%" maxLength="255" @input="filterValueOnChange" />
      </IconField>
    </div>
  </section>

  <div :class="{ 'main-inner-wrap vld-parent': !isNativePlatform(), 'px-5 py-4': isNativePlatform() }">
    <div class="w-full px-0 sm:px-4" style="max-width: 1120px" :class="{ 'mt-4': isNativePlatform() }">
      <div id="lectures-list-container">
        <teleport v-if="activeTour && activeTour.step == 'tour_lectures'" class="w-full bg-white" to="#tour-teleport-target">
          <div class="p-4 bg-white rounded-md">
            <CreditableLectures :upcomingLectures="upcomingLectures" :filteredUpcomingLectures="filteredUpcomingLectures" :filteredPastLectures="filteredPastLectures" @clearFilters="clearFilters" />
          </div>
        </teleport>
        <CreditableLectures :upcomingLectures="upcomingLectures" :filteredUpcomingLectures="filteredUpcomingLectures" :filteredPastLectures="filteredPastLectures" @clearFilters="clearFilters" />
        <ArchivedLectures :archivedLectures="this.archivedLectures" />
      </div>
    </div>
  </div>

  <section id="lecture_value_props" v-if="!loggedInUser" class="bg-white">
    <div class="max-w-screen-xl pt-4 pb-8 mx-auto" style="max-width: 1120px">
      <div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3">
        <div class="block p-8">
          <i class="mx-auto pi pi-check text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Earn on demand</h2>
          <p class="mt-1 text-sm">Watch a recent webinar and fill out a short quiz to instantly receive CME credits.</p>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-desktop text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Watch live</h2>
          <p class="mt-1 text-sm">Connect with peers and ask questions through live, interactive webinars given by Virtual Hallway specialists.</p>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-comments text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Consult with speakers</h2>
          <p class="mt-1 text-sm">Have a question about a patient you want to ask the speaker? Consult with them after the lecture on Virtual Hallway.</p>
        </div>
      </div>
    </div>
  </section>
  <InterestedInPresentingLectureDialog ref="interestedInPresentingLectureDialog" />
  <RequestCmeTopicDialog ref="requestCmeTopicDialog" />
  <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="false" />
  <Modal ref="askIntentionModal" class="mx-auto">
    <template v-slot:body>
      <div class="w-full px-6 py-8">
        <div class="flex flex-col items-center justify-center w-full px-10 py-8">
          <div class="text-xl font-bold text-secondary">What would you like to see?</div>
          <div class="w-full pt-8 pb-4">
            <Button @click="$refs.askIntentionModal.closeModal()" label="Watch Lectures" icon="pi pi-video" class="w-full p-button-secondary p-button-outlined" id="askIntentionLecturesButton" />
          </div>
          <div class="flex items-center w-full py-4 font-display">
            <div class="flex-grow border-b border-gray-500 border-solid line-h-0.5"></div>
            <div class="flex-1 mx-3 text-xs">OR</div>
            <div class="flex-grow border-b border-gray-500 border-solid line-h-0.5"></div>
          </div>
          <div class="w-full py-4">
            <Button @click="$router.push('poems')" label="Read POEMs" icon="pi pi-book" class="w-full p-button-secondary p-button-outlined" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import TutorialVideoDialog from '@/components/misc/TutorialVideoDialog.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import moment from 'moment';
import RequestCmeTopicDialog from '@/components/cme/RequestCmeTopicDialog.vue';
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import CreditableLectures from '@/components/cme/CreditableLectures.vue';
import OAuth from '@/views/public/OAuth.vue';
import OverlayPanel from 'primevue/overlaypanel';

import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    OAuth,
    Button,
    Loading,
    Dialog,
    Dropdown,
    InputText,
    TutorialVideoDialog,
    RequestCmeTopicDialog,
    RegistrationForm,
    CreditableLectures,
    OverlayPanel,
  },

  data() {
    return {
      showRegistration: false,
      lecture_menu_items: [
        {
          label: 'My Credits',
          icon: 'fas fa-award',
          command: () => {
            this.$router.push('/cme-certifications?type=lecture');
          },
        },
        {
          label: 'Become a Speaker',
          icon: 'pi pi-fw pi-user-plus',
          command: () => {
            this.$refs.interestedInPresentingLectureDialog.openDialog();
          },
        },
        {
          label: 'Request a Lecture',
          icon: 'pi pi-send',
          command: () => {
            this.$refs.requestCmeTopicDialog.openDialog();
          },
        },
        // {
        //   label: 'Popular',
        //   icon: 'pi pi-fw pi-star',
        //   command: () => {
        //     this.scrollToOnDemandAndSort();
        //   },
        // },
        // {
        //   label: 'Bookmarked',
        //   icon: 'pi pi-bookmark',
        //   command: () => {
        //     this.toggleFilterLecturesByIsBookmarked();
        //   },
        // },
        {
          label: 'Help',
          icon: 'pi pi-fw pi-question-circle',
          command: () => {
            this.$store.commit('setOpenedSupportSectionAccountAccordionIndex', 6);
          },
        },
      ],
      filterValue: '',
      filteredUpcomingLectures: [],
      filteredPastLectures: [],
      isLoading: false,
      selectedLectureSpecialty: null,
      selectedLecturePresenter: null,
      toggleIsBookmarkedFilter: false,
      allPresenterNames: [],
      allPresenterSpecialties: [],
      showFilterOverlay: false,
      lecturePresenterFilter: null,
      lectureSpecialtyFilter: null,
    };
  },
  computed: {
    ...mapGetters(['lectures', 'loggedInUser', 'isLoggedIn', 'isGP', 'isCme', 'activeTour']),
    upcomingLectures() {
      return this.lectures
        .slice()
        .reverse()
        .filter((lecture) => {
          return moment.utc(lecture.lecture_datetime).local().isAfter(moment.utc().subtract(60, 'minutes').local());
        });
    },
    pastLectures() {
      let oneYearAgo = moment().subtract(1, 'years');
      return this.lectures.filter((lecture) => {
        return moment.utc(lecture.lecture_datetime).local().isBetween(oneYearAgo, moment());
      });
    },
    archivedLectures() {
      return this.lectures.filter((lecture) => {
        return moment.utc(lecture.lecture_datetime).local().isBefore(moment().subtract(1, 'years').local());
      });
    },
  },
  methods: {
    ...mapActions(['getAllLectures', 'addNewLecture', 'submitInterestedInSpeaking', 'submitRequestCmeTopic']),
    filterLectures() {
      this.applyAllFilters();
    },

    filterLecturesByPresenter() {
      this.applyAllFilters();
    },

    filterLecturesBySpecialty() {
      this.applyAllFilters();
    },

    applyAllFilters() {
      let filteredPast = this.pastLectures.filter((lecture) => lecture.lecture_published && !lecture.associated_course_id);
      let filteredUpcoming = this.upcomingLectures.filter((lecture) => lecture.lecture_published && !lecture.associated_course_id);

      filteredPast = filteredPast.filter((lecture) => {
        let matchesPresenter = true;
        let matchesSpecialty = true;

        if (this.selectedLecturePresenter) {
          if (lecture.presenters && Array.isArray(lecture.presenters) && lecture.presenters.length > 0) {
            matchesPresenter = lecture.presenters.some((presenter) => presenter.presenter_name === this.selectedLecturePresenter);
          } else {
            matchesPresenter = lecture.presenter_name === this.selectedLecturePresenter;
          }
        }

        if (this.selectedLectureSpecialty) {
          if (lecture.presenters && Array.isArray(lecture.presenters) && lecture.presenters.length > 0) {
            matchesSpecialty = lecture.presenters.some((presenter) => presenter.presenter_specialty === this.selectedLectureSpecialty);
          } else {
            matchesSpecialty = lecture.presenter_specialty === this.selectedLectureSpecialty;
          }
        }

        return matchesPresenter && matchesSpecialty;
      });

      // Apply filters for upcoming lectures (same logic)
      filteredUpcoming = filteredUpcoming.filter((lecture) => {
        let matchesPresenter = true;
        let matchesSpecialty = true;

        if (this.selectedLecturePresenter) {
          if (lecture.presenters && Array.isArray(lecture.presenters) && lecture.presenters.length > 0) {
            matchesPresenter = lecture.presenters.some((presenter) => presenter.presenter_name === this.selectedLecturePresenter);
          } else {
            matchesPresenter = lecture.presenter_name === this.selectedLecturePresenter;
          }
        }

        if (this.selectedLectureSpecialty) {
          if (lecture.presenters && Array.isArray(lecture.presenters) && lecture.presenters.length > 0) {
            matchesSpecialty = lecture.presenters.some((presenter) => presenter.presenter_specialty === this.selectedLectureSpecialty);
          } else {
            matchesSpecialty = lecture.presenter_specialty === this.selectedLectureSpecialty;
          }
        }

        return matchesPresenter && matchesSpecialty;
      });

      this.filteredPastLectures = filteredPast;
      this.filteredUpcomingLectures = filteredUpcoming;
    },

    filterPastLecturesByTotalViews() {
      this.filteredPastLectures = this.pastLectures.sort(({ total_views: a }, { total_views: b }) => b - a);
      this.$forceUpdate();
    },
    toggleFilterLecturesByIsBookmarked() {
      if (this.toggleIsBookmarkedFilter == false) {
        this.toggleIsBookmarkedFilter = true;
        this.filterLecturesByIsBookmarked();
      } else {
        this.toggleIsBookmarkedFilter = false;
        this.filteredPastLectures = this.pastLectures;
        this.filteredUpcomingLectures = this.upcomingLectures;
        this.$forceUpdate();
      }
    },
    filterLecturesByIsBookmarked() {
      this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.bookmarked);
      this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.bookmarked);
      this.$forceUpdate();
    },
    filterValueOnChange() {
      if (this.filterValue === '') {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_published);
        this.filteredUpcomingLectures = this.upcomingLectures;
      } else {
        // this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_title.toLowerCase().includes(this.filterValue.toLowerCase()));
        // this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.lecture_title.toLowerCase().includes(this.filterValue.toLowerCase()));
        //filters by all values
        // https://stackoverflow.com/questions/45991273/how-to-use-filter-to-search-in-multiple-key-values-of-objects-in-an-array
        this.filteredPastLectures = this.pastLectures.filter((lecture) => Object.values(lecture).some((val) => String(val).toLowerCase().includes(this.filterValue.toLowerCase())));
        this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => Object.values(lecture).some((val) => String(val).toLowerCase().includes(this.filterValue.toLowerCase())));
      }
    },
    clearFilters() {
      this.filterValue = '';
      this.selectedLectureSpecialty = null;
      this.selectedLecturePresenter = null;
      this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_published);
      this.filteredUpcomingLectures = this.upcomingLectures;
      this.applyAllFilters();
    },
    isCurrentLecture(lecture) {
      let lectureEndTime = moment.utc(lecture.lecture_datetime).add(60, 'minutes');
      return moment.utc().isBetween(moment.utc(lecture.lecture_datetime), lectureEndTime);
    },
    scrollToOnDemandAndSort() {
      this.$refs.onDemandLectures.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
      this.filterPastLecturesByTotalViews();
    },
    openFilterDialog() {
      this.lecturePresenterFilter = this.selectedLecturePresenter;
      this.lectureSpecialtyFilter = this.selectedLectureSpecialty;
      this.showFilterOverlay = true;
    },

    clearFiltersDialog() {
      this.lecturePresenterFilter = null;
      this.lectureSpecialtyFilter = null;
    },

    applyFiltersDialog() {
      this.selectedLecturePresenter = this.lecturePresenterFilter;
      this.selectedLectureSpecialty = this.lectureSpecialtyFilter;

      this.applyAllFilters();

      this.showFilterOverlay = false;
    },
    toggleFilterOverlay(event) {
      this.lecturePresenterFilter = this.selectedLecturePresenter;
      this.lectureSpecialtyFilter = this.selectedLectureSpecialty;
      this.$refs.filterOverlay.toggle(event);
    },

    clearFiltersPanel() {
      this.lecturePresenterFilter = null;
      this.lectureSpecialtyFilter = null;
    },

    applyFiltersPanel() {
      this.selectedLecturePresenter = this.lecturePresenterFilter;
      this.selectedLectureSpecialty = this.lectureSpecialtyFilter;

      this.applyAllFilters();
      this.$refs.filterOverlay.hide();
    },
  },
  created() {
    this.isLoading = true;
    this.getAllLectures()
      .then(() => {
        const presenterSet = new Set();
        const specialtySet = new Set();

        this.lectures.forEach((lecture) => {
          if (lecture.presenters && Array.isArray(lecture.presenters)) {
            lecture.presenters.forEach((presenter) => {
              if (presenter && presenter.presenter_name) {
                presenterSet.add(presenter.presenter_name);
              }

              if (presenter && presenter.presenter_specialty) {
                specialtySet.add(presenter.presenter_specialty);
              }
            });
          }
        });

        this.allPresenterNames = Array.from(presenterSet).sort((a, b) => a.localeCompare(b));
        this.allPresenterSpecialties = Array.from(specialtySet).sort((a, b) => a.localeCompare(b));

        this.filterLectures();
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  mounted() {
    if (this.$route.query.askIntention && this.displayPoems()) {
      this.$refs.askIntentionModal.openModal();
      this.$route.query = {};
    }
  },
};
</script>

<style>
.p-menubar-root-list {
  min-width: 230px;
}
.dark-blue {
  color: #5b76a2;
}
.subheading-blue {
  color: #859dc4;
}
.video-background {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: gray;
}
.cme-label {
  color: white;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.cme-live-label {
  color: white;
  background-color: #5e76ff;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  z-index: 2;
}
.credit-claimed-label {
  color: white;
  bottom: 1.5rem;
  right: 0;
  position: absolute;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.image-alt-rounded-corners {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.p-menubar {
  background: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #394353;
}

.p-menubar .p-menubar-root-list > .p-menuitem:hover {
  background: #f0f2f4;
  border-radius: 5px;
}
.p-menuitem {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    flex-direction: row-reverse;
    justify-content: start;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > svg,
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > .pi {
    margin-left: 0.5rem;
  }
}
#lecture_filter_dropdown,
#lecture_presenter_filter_dropdown {
  border: none;
  font-family: 'Poppins';
  font-size: 0.8rem;
}
#lecture_filter_dropdown > span,
#lecture_presenter_filter_dropdown > span {
  border: none;
  font-weight: 600;
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
  font-size: 0.8rem;
}
#lecture_filter_dropdown:hover,
#lecture_presenter_filter_dropdown:hover {
  background: #f0f2f4;
}
#lecture_filter_dropdown_list > span {
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
}
#lecture_filter_dropdown_list,
#lecture_presenter_filter_dropdown_list {
  font-size: 0.8rem;
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
}

.p-selectbutton .p-togglebutton:first-child {
  border-left-width: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-selectbutton .p-togglebutton:last-child {
  border-left-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
