<template>
  <div class="justify-start gap-1.5 sm:gap-2.5 flex my-2 w-full">
    <div class="relative min-w-10">
      <div class="w-10 h-10 bg-gray-300 rounded-full justify-start items-start gap-2.5 flex min-w-10 z-10">
        <img class="object-cover w-10 h-10 rounded-full" v-if="comment?.user?.profile" v-bind:src="comment.user.profile.profile_picture_location" />
        <img class="object-cover w-10 h-10 rounded-full" v-else src="https://app.virtualhallway.ca/storage/user_profile_pictures/default.png" />
      </div>
      <div class="absolute bottom-0 z-0 flex items-center justify-center w-8 mb-3 cursor-pointer top-12 left-1 group" aria-hidden="true" v-if="comment.comments?.length > 0">
        <div data-testid="main-thread-line" class="w-[1px] h-full group-hover:bg-gray-500 bg-gray-300"></div>
      </div>
    </div>

    <div class="w-full pl-1">
      <div class="flex-col items-start justify-start gap-1">
        <h5 class="text-md font-semibold leading-snug text-gray-900" v-if="comment.user">{{ comment.user.first_name }} {{ comment.user.last_name }}</h5>
        <h5 class="text-sm font-semibold leading-snug text-gray-900" v-else>Deleted</h5>
        <h6 class="text-xs font-normal leading-5 text-gray-500">{{ formatDateTimeToSpecifiedFormat(comment.created_at, 'MMM D, YYYY • h:mma', true) }}</h6>
      </div>
      <div class="mt-3 leading-snug text-gray-800 break-words overflow-hidden md:max-w-full text-sm">{{ comment.text }}</div>
      <div class="flex flex-row" v-if="comment.user">
        <div class="flex items-center justify-start px-3 py-1 mt-2 cursor-pointer flew-row max-w-20 hover:bg-gray-100 hover:border-gray-400 rounded-xl" v-on:click.stop @click="showReplyInputOnClick" id="showReplyToCommentButton">
          <i class="pr-2 pi pi-reply" style="font-size: 0.9rem"></i>
          <span class="text-xs font-base">Reply</span>
        </div>
        <div class="flex items-center justify-center px-3 py-1 mt-2 cursor-pointer flew-row max-w-20 hover:bg-gray-100 hover:border-gray-400 rounded-xl" v-on:click.stop @click="showMenuOnClick" id="userCommentMenuButton">
          <i class="pi pi-ellipsis-h" style="font-size: 0.9rem"></i>
        </div>
      </div>

      <div class="w-full pt-4 relative" v-if="showReplyInput">
        <div class="w-full relative">
          <Textarea autoFocus v-model="replyComment" :autoResize="true" rows="2" cols="30" required maxLength="65535" id="replyCommentTextarea" @keydown.enter="handleKeyDown" />
          <div class="absolute bottom-2 right-2">
            <span class="hidden sm:block text-xs text-gray-500">{{ isMac ? '⌘' : 'CTRL' }} + ↵ to submit</span>
          </div>
        </div>

        <div class="flex justify-end mt-2">
          <Button label="Submit" class="p-button-secondary p-button-sm" @click="submitComment" :loading="isLoadingComment" :disabled="isLoadingComment" id="submitReplyCommentButton" />
        </div>
      </div>
      <div v-if="comment?.comments">
        <div v-for="commentReply in comment.comments.filter((comment) => comment.user)" :key="commentReply.id" class="pt-3 mt-3 break-words">
          <UserComment :comment="commentReply" :commentable="commentable" :depth="depth + 1" :discussionComment="discussionComment" :commentableType="commentableType" />
        </div>
      </div>
    </div>
  </div>
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  inject: ['mixpanel'],
  props: {
    comment: {
      type: Object,
      required: true,
    },
    commentable: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    discussionComment: {
      type: Object,
      required: true,
    },
    commentableType: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      showReplyInput: false,
      replyComment: null,
      isLoadingComment: false,
      isLoadingComments: false,
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['createUserComment', 'deleteUserComment', 'reportUserComment']),
    showReplyInputOnClick() {
      this.showReplyInput = !this.showReplyInput;
      this.replyComment = null;
    },
    handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        event.preventDefault();
        this.submitComment();
      }
    },
    submitComment() {
      if (!this.replyComment) {
        return;
      }

      this.isLoadingComment = true;
      this.createUserComment({
        commentable: this.commentable,
        commentable_id: this.commentable.id,
        commentable_type: this.commentableType,
        text: this.replyComment,
        user_comment_discussion_id: this.discussionComment.id,
        user_comment_parent_id: this.comment.id,
      })
        .then((data) => {
          this.discussionComment.comments.push(data);
          this.replyComment = null;
          this.isLoadingComment = false;
          this.showReplyInput = false;
        })
        .catch(() => {
          this.isLoadingComment = false;
          this.showGenericErrorToast();
        });
    },
    showMenuOnClick(event) {
      this.menuItems = [];
      this.menuItems.push({
        label: 'Report',
        icon: 'pi pi-flag',
        command: () => {
          this.reportUserComment({
            comment_id: this.comment.id,
            commentable_type: this.commentableType,
          })
            .then(() => {
              this.$toast.removeAllGroups();
              this.$toast.add({
                severity: 'success',
                summary: 'Comment Reported',
                detail: 'Comment has successfully been reported.',
                life: 3000,
              });
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        },
      });

      if (this.comment.user.id === this.loggedInUser.id) {
        this.menuItems.push({
          label: 'Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.deleteUserComment({
              comment_id: this.comment.id,
              comment: this.comment,
              commentable_type: this.commentableType,
            })
              .then(() => {
                this.$toast.removeAllGroups();
                this.$toast.add({
                  severity: 'success',
                  summary: 'Comment Deleted',
                  detail: 'Your comment has successfully been deleted.',
                  life: 3000,
                });
              })
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        });
      }

      this.$refs.menu.toggle(event);
    },
  },
  created() {},
};
</script>

<style></style>
