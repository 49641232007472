import axios from 'axios';
import globals from '../../globals';

const state = {
  patients: [],
};

const getters = {
  patients: (state) => {
    return state.patients;
  },
};

const actions = {
  getAccuroApiAccessToken(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAccuroApiAccessTokenUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchAccuroEmrPatient(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.searchAccuroEmrPatientUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOauthExternalClients(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOauthExternalClientsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadAccuroRepliForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadAccuroRepliFormUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
          responseType: 'blob', // Set response type to blob for file download
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setSummaries(state, data) {
    state.patients = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
