import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { globalMixin } from '../../mixins';

const state = {
  openedSupportSectionKnowledgebaseAccordionIndex: null,
  openedSupportSectionAccountAccordionIndex: null,
  isMobileAppActive: false,
  showTheSideBar: false,
  globalDialogComponentName: null,
  showFileViewer: false,
  fileViewerData: {
    content: null,
    fileName: '',
  },
};

const getters = {
  openedSupportSectionKnowledgebaseAccordionIndex: (state) => {
    return state.openedSupportSectionKnowledgebaseAccordionIndex;
  },
  openedSupportSectionAccountAccordionIndex: (state) => {
    return state.openedSupportSectionAccountAccordionIndex;
  },
  isMobileAppActive: (state) => {
    return state.isMobileAppActive;
  },
  showTheSideBar: (state) => {
    return state.showTheSideBar;
  },
  globalDialogComponentName: (state) => {
    return state.globalDialogComponentName;
  },
  fileViewerData: (state) => {
    return state.fileViewerData;
  },
  showFileViewer: (state) => {
    return state.showFileViewer;
  },
};

// actions
const actions = {
  viewDownloadedFile(context, data) {
    return new Promise((resolve, reject) => {
      if (globalMixin.methods.isNativePlatform()) {
        context
          .dispatch('viewDownloadedFileInNativePlatform', { downloadedFileContent: data.downloadedFileContent, fileName: data.fileName })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.log('downloading file in native platform failed', error);
            reject(error);
          });
      } else {
        const extension = data.fileName.split('.').pop().toLowerCase();
        const mimeTypes = {
          pdf: 'application/pdf',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          svg: 'image/svg+xml',
        };
        const fileType = mimeTypes[extension] || 'application/octet-stream';
        if (fileType != 'application/octet-stream') {
          context.commit('setFileViewerData', {
            content: data.downloadedFileContent,
            fileName: data.fileName,
          });
        } else {
          var fileURL = window.URL.createObjectURL(new Blob([data.downloadedFileContent]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', data.fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
        resolve(true);
      }
    });
  },
  viewDownloadedFileInNativePlatform(context, data) {
    return new Promise((resolve, reject) => {
      // https://stackoverflow.com/a/18650249
      var reader = new FileReader();
      var base64;
      reader.readAsDataURL(new Blob([data.downloadedFileContent]));
      reader.onloadend = function () {
        base64 = reader.result;
        // https://forum.ionicframework.com/t/capacitor-writefile-saving-pdf-file-is-in-invalid-format/158633
        Filesystem.writeFile({
          path: data.fileName,
          data: base64,
          directory: Directory.Documents,
          // encoding: Encoding.UTF8,
        })
          .then(() => {
            console.log('File Written');
            Filesystem.getUri({
              directory: Directory.Documents,
              path: data.fileName,
            })
              .then((getUriResult) => {
                FileOpener.open({
                  filePath: getUriResult.uri,
                })
                  .then(() => {
                    console.log('File opened');
                    resolve(true);
                  })
                  .catch((error) => {
                    console.log('Error openening file', error);
                    reject(error);
                  });
              })
              .catch((error) => {
                console.log('error getting uri', error);
                reject(error);
              });
          })
          .catch((error) => {
            console.log('Error writing file', error);
            reject(error);
          });
      };
    });
  },
  // All future state that needs to be purged after log out should be added here
  purgeLocalStorage(context) {
    context.commit('purgeLoggedInUser');
    context.commit('purgePoems');
    context.commit('removeExternalReferralRequestCode');
    context.commit('purgeEncounterNote');
  },
};

// mutations
const mutations = {
  setOpenedSupportSectionKnowledgebaseAccordionIndex(state, data) {
    state.openedSupportSectionKnowledgebaseAccordionIndex = data;
  },
  setOpenedSupportSectionAccountAccordionIndex(state, data) {
    state.openedSupportSectionAccountAccordionIndex = data;
  },
  setIsMobileAppActive(state, data) {
    state.isMobileAppActive = data;
  },
  setShowTheSideBar(state, data) {
    state.showTheSideBar = data;
  },
  setGlobalDialogComponentName(state, data) {
    state.globalDialogComponentName = data;
  },
  setFileViewerData(state, data) {
    state.fileViewerData = data;
    state.showFileViewer = true;
  },
  resetFileViewerData(state) {
    state.fileViewerData = {
      content: null,
      fileName: '',
    };
    state.showFileViewer = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
