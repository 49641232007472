// const mainApiUrl = 'http://127.0.0.1:8000/api/';
const mainApiUrl = 'https://app.virtualhallway.ca/api/';

const globals = {
  APIs: {
    mainApiUrl() {
      return mainApiUrl;
    },
    /// User APIs
    loginUrl() {
      return mainApiUrl + 'login';
    },
    registerOauthUrl() {
      return mainApiUrl + 'login/oauth';
    },
    registerUrl() {
      return mainApiUrl + 'register';
    },
    logoutUrl() {
      return mainApiUrl + 'logout';
    },
    allUserUrl() {
      return mainApiUrl + 'user';
    },
    getByRoleUrl(roleSlug) {
      return mainApiUrl + 'user/get-by-role/' + roleSlug;
    },
    getUserByIdUrl(userId) {
      return mainApiUrl + 'user/get-by-id/' + userId;
    },
    setupUserAccountUrl() {
      return mainApiUrl + 'setup-account';
    },
    updateOfficeFacilityNumberUrl() {
      return mainApiUrl + 'user/office-facility-number';
    },
    verifyPasswordResetUrl() {
      return mainApiUrl + 'verify-password-reset';
    },
    sendPasswordResetEmailUrl() {
      return mainApiUrl + 'send-password-reset-email';
    },
    resetPasswordUrl() {
      return mainApiUrl + 'reset-password';
    },
    verifyEmailUrl() {
      return mainApiUrl + 'verify-email';
    },
    resendEmailVerificationEmailUrl() {
      return mainApiUrl + 'resend-verification-email';
    },
    updateUserAccountInfoUrl(userId) {
      return mainApiUrl + `user/${userId}`;
    },
    updateUserProfileInfoUrl() {
      return mainApiUrl + 'user/profile';
    },
    changePasswordUrl() {
      return mainApiUrl + `user/change-password`;
    },
    uploadProfilePictureUrl() {
      return mainApiUrl + `user/upload-profile-picture`;
    },
    closeAccountUrl() {
      return mainApiUrl + `user/close-account`;
    },
    verifyOtpForUserLoginUrl() {
      return mainApiUrl + `user/verify-otp-for-login`;
    },
    sendOtpToUser() {
      return mainApiUrl + `user/resend-otp`;
    },
    update2faSettingsUrl() {
      return mainApiUrl + `user/update-2fa-settings`;
    },
    checkIfUserIsAllowedToMakeReferralURL(userId) {
      return mainApiUrl + `user/allow-make-referrals/` + userId;
    },
    updateSpecialistPreferenceURL() {
      return mainApiUrl + `user/update-specialist-preference`;
    },
    getExpertiseAreaByIdUrl(expertiseAreaId) {
      return mainApiUrl + `expertise-area/` + expertiseAreaId;
    },
    createExpertiseAreaUrl() {
      return mainApiUrl + `create-expertise-area`;
    },
    attachExpertiseAreasUrl() {
      return mainApiUrl + `attach-expertise-areas`;
    },
    removeExpertiseAreasUrl() {
      return mainApiUrl + `remove-expertise-areas`;
    },
    confirmPasswordUrl() {
      return mainApiUrl + `user/confirm-password`;
    },
    getUsersByOrgUnitIdUrl(orgUnitId) {
      return mainApiUrl + `user/get-by-org-unit-id/${orgUnitId}`;
    },
    getUsersByBookingHallwayIdUrl(bookingHallwayId) {
      return mainApiUrl + `user/get-by-booking-hallway-id/${bookingHallwayId}`;
    },
    verifyUserInviteCodeUrl() {
      return mainApiUrl + `user/verify-user-invite-code`;
    },
    removeUserFromByBookingHallwayByIdUrl() {
      return mainApiUrl + `user/remove-booking-hallway-user-by-user-id`;
    },
    getUsersByRoleAndOrgUnitIdUrl(roleName, orgUnitId) {
      return mainApiUrl + `user/get-by-role-and-org-unit-id/${roleName}/${orgUnitId}`;
    },
    getOnboardingUsersOfOrgUnitUrl(orgUnitId) {
      return mainApiUrl + `user/get-by-org-unit-id/${orgUnitId}/onboarding`;
    },
    updateNotificationSettingUrl(id) {
      return mainApiUrl + `user/notification-setting/${id}`;
    },
    refreshTokenUrl() {
      return mainApiUrl + `refresh-token`;
    },
    getAllAvatarUrlsUrl() {
      return mainApiUrl + 'avartar-urls';
    },
    updateUserAvatarUrl() {
      return mainApiUrl + 'user/update-avatar';
    },
    updateUserActionUrl() {
      return mainApiUrl + 'user-action';
    },
    createUserFeedbackUrl() {
      return mainApiUrl + 'create-user-feedback';
    },
    getUserActionsUrl() {
      return mainApiUrl + 'user-action';
    },
    createWhitelistedFaxNumberURL() {
      return mainApiUrl + 'user/create-whitelist-fax-number';
    },
    getWhitelistedFaxNumbersURL(userId) {
      return mainApiUrl + 'user/get-whitelist-fax-numbers/' + userId;
    },
    updateWhitelistedFaxNumberURL() {
      return mainApiUrl + 'user/update-whitelist-fax-number';
    },
    deleteWhitelistedFaxNumberURL() {
      return mainApiUrl + 'user/delete-whitelist-fax-number';
    },
    checkCurrentUsersAvailabilityUrl(user_id) {
      return mainApiUrl + `user/check-current-users-availability/${user_id}`;
    },
    getOfferedServicesOfUsersUrl(user_ids) {
      return mainApiUrl + `user/get-offered-services-of-users`;
    },
    requestSpecialistServiceUrl() {
      return mainApiUrl + 'user/request-specialist-service';
    },
    getLoggedInUserUrl() {
      return mainApiUrl + 'user/logged-in-user';
    },
    createAuditUrl() {
      return mainApiUrl + 'audit';
    },
    saveUserAgreedToPushNotificationsUrl() {
      return mainApiUrl + 'save-user-agreed-to-push-notifications';
    },
    getUserEarningsUrl() {
      return mainApiUrl + 'user/earnings';
    },

    /// Schedule APIs
    addScheduleUrl() {
      return mainApiUrl + 'schedule' + '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    deleteScheduleByIdUrl(scheduleId) {
      return mainApiUrl + 'schedule/' + scheduleId;
    },
    getUpcomingScheduleOfUserUrl(userId) {
      return mainApiUrl + 'schedule/user/' + userId + '/upcoming';
    },
    getUpcomingScheduleWithScheduleBufferOfUserUrl(userId) {
      return mainApiUrl + 'schedule/user/' + userId + '/upcoming-with-buffer';
    },
    getNextAvailabilityOfSpecialtiesUrl(actingUserId) {
      return mainApiUrl + `schedule/specialty/get-next-availiability?user_id=${actingUserId}`;
    },

    /// Referral APIs
    getReferralsPaginationUrl() {
      return mainApiUrl + 'referral/pagination';
    },
    addReferralUrl() {
      return mainApiUrl + 'referral';
    },
    getReferralById(id) {
      return mainApiUrl + 'referral/' + id;
    },
    updateReferralUrl(referralId) {
      return mainApiUrl + 'referral/' + referralId;
    },
    cancelReferralUrl(userId) {
      return mainApiUrl + 'referral/user/' + userId + '/cancel';
    },
    sendNudgeMessageUrl() {
      return mainApiUrl + 'referral/send-nudge-message';
    },
    sendRunningLateMessageUrl() {
      return mainApiUrl + 'referral/send-running-late-message';
    },
    getReferralAddendumsByReferralIdUrl(referral_id) {
      return mainApiUrl + 'get-referral-addendums';
    },
    createReferralAddendumUrl() {
      return mainApiUrl + 'create-referral-addendum';
    },
    createReferralNotesUrl(userId) {
      return mainApiUrl + 'referral/user/' + userId + '/update-referral-notes';
    },
    getAttachOrgUnitToReferralUrl() {
      return mainApiUrl + 'referral/attach-org-unit';
    },
    getDetachOrgUnitToReferralUrl() {
      return mainApiUrl + 'referral/detach-org-unit';
    },
    getAttachOrgUnitToMultipleReferralsUrl() {
      return mainApiUrl + 'referral/multiple-referrals/attach-org-unit';
    },
    getDetachOrgUnitToMultipleReferralsUrl() {
      return mainApiUrl + 'referral/multiple-referrals/detach-org-unit';
    },
    downloadReferralIcsFileUrl() {
      return mainApiUrl + 'referral/download-ics-file';
    },
    bookDemoAsReferralPersonUrl() {
      return mainApiUrl + 'referral/book-demo-as-referral-person';
    },
    refereeHasSeenResponseFormUrl() {
      return mainApiUrl + 'referral/referee-has-seen-response-form';
    },
    downloadPdfOfCombinedFormUrl(referralId) {
      return mainApiUrl + `referral/download-combined-pdf/${referralId}`;
    },
    getExternalReferralRequestByCodeUrl() {
      return mainApiUrl + `external-referral-request/get-by-request-code`;
    },
    exportBillingCsvUrl() {
      return mainApiUrl + 'export-billing-csv';
    },
    getAttachedReferralsUrl() {
      return mainApiUrl + 'get-attached-referrals';
    },
    updateAttachedReferralsUrl() {
      return mainApiUrl + 'update-attached-referrals';
    },
    createOrUpdateEncounterNoteUrl() {
      return mainApiUrl + 'encounter-note';
    },
    faxCombinedReferralFormsUrl() {
      return mainApiUrl + 'referral/fax-pdf';
    },

    /// Referral Form APIs
    updateReferralFormUrl(referralFormId) {
      return mainApiUrl + 'referral-form/' + referralFormId;
    },
    saveReferralFormUrl(referralFormId) {
      return mainApiUrl + 'referral-form/save/' + referralFormId;
    },
    getReferralFormFieldsBySpecialtyId(specialtyId) {
      return mainApiUrl + 'referral-form-field/specialty/' + specialtyId;
    },
    getReferralFormFieldsByReferralIdUrl(referralId) {
      return mainApiUrl + 'referral-form-field/referral/' + referralId;
    },
    uploadFilesForReferralFormUrl() {
      return mainApiUrl + 'referral-form/upload-file';
    },
    downloadFilesOfReferralFormFieldUrl() {
      return mainApiUrl + 'referral-form/download-files';
    },
    viewFileOfReferralFormFieldUrl() {
      return mainApiUrl + 'referral-form/view-file';
    },
    deleteUploadedFilesFromReferralFormUrl() {
      return mainApiUrl + 'referral-form/delete-files';
    },
    downloadPdfOfReferralFormUrl(referralFormId) {
      return mainApiUrl + `referral-form/download-pdf/${referralFormId}`;
    },
    bookAnotherConsultUrl(referralId) {
      return mainApiUrl + 'referral/book-another-consult/' + referralId;
    },
    faxPdfOfReferralFormUrl() {
      return mainApiUrl + `referral-form/fax-pdf`;
    },
    faxPdfOfReferralResponseFormUrl() {
      return mainApiUrl + `referral-response-form/fax-pdf`;
    },
    checkIfPatientBookedWithinExclusionPeriodUrl() {
      return mainApiUrl + 'referral-form/check-if-patient-booked-within-exclusion-period';
    },
    getAllUsersPatientsRecentReferralFormURL(userId) {
      return mainApiUrl + 'referral-form/get-all-users-patients-recent-referral-form/' + userId;
    },
    addAddendumToReferralFormUrl() {
      return mainApiUrl + 'referral-form-addendum';
    },
    getReferralFormAddendumsUrl(referralFormId) {
      return mainApiUrl + `referral-form-addendum/referral-form/${referralFormId}`;
    },
    signOffReferralFormUrl(referralFormId) {
      return mainApiUrl + `referral-form/sign-off/${referralFormId}`;
    },
    getDiagnosticCodesUrl(query) {
      return 'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&maxList=10&terms=' + query;
    },
    getDiagnosticCodesOntarioUrl() {
      return mainApiUrl + 'referral-form/diagnostic-codes-ontario';
    },
    requestAdditionalReferralFormFieldsUrl() {
      return mainApiUrl + `onboarding-form/additional-form-fields`;
    },

    /// Referral Response Form APIs
    updateReferralResponseFormUrl(referralResponseFormId) {
      return mainApiUrl + 'referral-response-form/' + referralResponseFormId + '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    saveReferralResponseFormUrl(referralResponseFormId) {
      return mainApiUrl + 'referral-response-form/save/' + referralResponseFormId + '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    addAddendumToReferralResponseFormUrl() {
      return mainApiUrl + 'referral-response-form-addendum';
    },
    getReferralResponseFormAddendums(referralResponseFormId) {
      return mainApiUrl + `referral-response-form-addendum/referral-response-form/${referralResponseFormId}`;
    },
    downloadPdfOfResponseFormUrl(referralResponseFormId) {
      return mainApiUrl + `referral-response-form/download-pdf/${referralResponseFormId}`;
    },

    /// Referral Response Form Related Files APIs
    uploadReferralResponseFormRelatedFilesUrl() {
      return mainApiUrl + `referral-response-form-related-file`;
    },
    getReferralResponseFormRelatedFilesByReferralIdUrl() {
      return mainApiUrl + `referral-response-form-related-file/get-by-referral-id`;
    },
    downloadResponseFormRelatedFileUrl() {
      return mainApiUrl + `referral-response-form-related-file/download-by-id`;
    },
    deleteResponseFormRelatedFileUrl() {
      return mainApiUrl + `referral-response-form-related-file/delete`;
    },
    getAllReferralResponseRelatedFileByUserIdUrl() {
      return mainApiUrl + `referral-response-form-related-file`;
    },
    addResponseFormRelatedFileFavoriteUserUrl() {
      return mainApiUrl + `referral-response-form-related-file/add-favorite-user`;
    },
    removeResponseFormRelatedFileFavoriteUserUrl() {
      return mainApiUrl + `referral-response-form-related-file/remove-favorite-user`;
    },

    /// Specialty APIs
    getAllSpecialtiesUrl() {
      return mainApiUrl + 'specialty';
    },
    getSpecialtyByIdUrl(specialtyId) {
      return mainApiUrl + `specialty/${specialtyId}`;
    },
    getSpecialtiesUrl() {
      return mainApiUrl + `specialty/get-specialties`;
    },
    getEconsultCountsOfUsersBySpecialtyIdUrl(specialtyId) {
      return mainApiUrl + 'user/specialty/' + specialtyId + '/econsult';
    },

    /// Province API
    getAllProvinceUrl() {
      return mainApiUrl + 'provinces-of-practice';
    },

    /// Onboarding APIs
    uploadPhotoIdURL() {
      return mainApiUrl + 'upload-photo-id';
    },
    getPhotoIdURL(user_id) {
      return mainApiUrl + 'photo-id/user/' + user_id;
    },
    agrreeToEulaURL(onboardingFormId) {
      return mainApiUrl + 'onboarding-form-agree-to-eula/' + onboardingFormId;
    },
    getOnboardingFormURL(userId) {
      return mainApiUrl + 'onboarding-form/user/' + userId;
    },
    updateBillingArrangementURL() {
      return mainApiUrl + 'billing-arrangement';
    },
    uploadBillingArrangementURL() {
      return mainApiUrl + 'upload-billing-arrangement';
    },
    getBillingArrangementURL(data) {
      return mainApiUrl + 'billing-arrangement/user/' + data.userId + '/province/' + data.practiceProvinceId;
    },
    downloadUnSignedBillingArrangementFormUrl(data) {
      return mainApiUrl + 'download-unsigned-billing-arrangement-form/user/' + data.userId + '/province/' + data.practiceProvinceId;
    },
    downloadAbbaFormUrl() {
      return mainApiUrl + 'abba-form';
    },

    /// Org Unit APIs
    inviteUsersByEmailUrl() {
      return mainApiUrl + 'user/invite-users-by-emails';
    },

    /// Contact Support APIs
    contactSupportUrl() {
      return mainApiUrl + 'contact-support';
    },
    availabilityFeedbackUrl() {
      return mainApiUrl + 'availability-feedback';
    },
    supportCallMeUrl() {
      return mainApiUrl + 'support-call-me';
    },

    /// Type Form APIs
    getTypeFormSurveyForUserUrl() {
      return mainApiUrl + 'get-type-form-survey-for-user';
    },

    /// Invite Colleague APIs
    colleagueInviteUrl() {
      return mainApiUrl + 'invite-user';
    },

    /// Text iOS App Link APIs
    textAppLinkUrl() {
      return mainApiUrl + 'text-app-link';
    },

    // Performance Page Logging
    performancePageLogUrl() {
      return mainApiUrl + 'user/performance-page-log';
    },

    // Feed Link Logging
    feedLinkClickLogUrl() {
      return mainApiUrl + 'user/feed-link-click-log';
    },

    // Lectures
    getAllLecturesUrl() {
      return mainApiUrl + 'lectures/get-all-lectures';
    },
    getRelatedLecturesUrl() {
      return mainApiUrl + 'lectures/get-related-lectures';
    },
    getUpcomingLecturesUrl() {
      return mainApiUrl + 'lectures/get-upcoming-lectures';
    },
    getCmeCreditsOfUserUrl() {
      return mainApiUrl + 'cme-credit/pagination';
    },
    getLectureRegistrantsCountUrl(zoom_url) {
      return mainApiUrl + 'lectures/get-lecture-registrants-count/' + zoom_url;
    },
    getZoomRegistrantTokenUrl() {
      return mainApiUrl + 'lectures/get-zoom-registrant-token/';
    },
    getZoomSdkSignatureUrl() {
      return mainApiUrl + 'lectures/get-zoom-sdk-signature/';
    },
    downloadCmeCreditUrl() {
      return mainApiUrl + 'cme-credit/download';
    },
    createCmeCreditUrl() {
      return mainApiUrl + 'cme-credit';
    },
    downloadCmeCertificateUrl() {
      return mainApiUrl + 'cme-certificate/download';
    },
    getCmeCertificatesOfUserUrl() {
      return mainApiUrl + 'cme-certificate/pagination';
    },
    getCmeActualAndVirtualCertificatesByUserUrl() {
      return mainApiUrl + 'cme-certificate/get-actual-and-virtual-certificates-by-user';
    },
    toggleLectureBookmarkUrl() {
      return mainApiUrl + 'lectures/toggle-lecture-bookmark';
    },
    getLectureMaterialFilesByLectureIdUrl() {
      return mainApiUrl + 'lectures/get-lecture-material-files';
    },
    downloadLectureMaterialFileUrl() {
      return mainApiUrl + 'lectures/download-lecture-material-file';
    },
    submitedInterestedInSpeakingUrl() {
      return mainApiUrl + 'lectures/interested-in-speaking';
    },
    requestCmeTopicUrl() {
      return mainApiUrl + 'lectures/request-cme-topic';
    },
    registerUserForZoomLectureUrl() {
      return mainApiUrl + 'lectures/register-user-for-zoom-lecture';
    },
    getLecturesBySpecialtyIdUrl() {
      return mainApiUrl + 'lectures/get-lectures-by-specialty-id';
    },

    // start SmartPath
    getSmartPathUrl(smart_path_id) {
      return mainApiUrl + 'smartpath/smartpath/' + smart_path_id;
    },
    getUsersSmartPathsUrl(user_id) {
      return mainApiUrl + 'smartpath/smartpaths/' + user_id;
    },
    getUsersPublicSmartPathsUrl() {
      return mainApiUrl + 'smartpath/public-smartpaths';
    },
    saveSmartPathUrl() {
      return mainApiUrl + 'smartpath/save';
    },
    saveSmartPathTransaction() {
      return mainApiUrl + 'smartpath/save-transaction';
    },
    getSmartPathTransactionsUrl() {
      return mainApiUrl + 'smartpath/smartpath-transactions';
    },
    downloadLabAccessFormUrl() {
      return mainApiUrl + 'smartpath/lab-form';
    },
    getSmartPathTransactionHistoryUrl(user_id) {
      return mainApiUrl + 'smartpath/smartpath-transaction-history/' + user_id;
    },
    getSmartPathTransactionUrl(transaction_id) {
      return mainApiUrl + 'smartpath/smartpath-transaction/' + transaction_id;
    },
    updateTransactionStatusUrl() {
      return mainApiUrl + 'smartpath/update-transaction-status';
    },
    downloadSmartPathResultUrl() {
      return mainApiUrl + 'smartpath/download-result';
    },
    faxSmartPathResultUrl() {
      return mainApiUrl + 'smartpath/fax-result';
    },
    toggleTransactionSeenUrl() {
      return mainApiUrl + 'smartpath/toggle-transaction-seen';
    },
    getAvailableContributorsUrl(smart_path_id) {
      return mainApiUrl + 'smartpath/get-available-contributors/' + smart_path_id;
    },
    attachContributorsUrl() {
      return mainApiUrl + 'smartpath/attach-contributors';
    },
    detachContributorsUrl() {
      return mainApiUrl + 'smartpath/detach-contributors';
    },
    attachSmartPathOrgUnitsUrl() {
      return mainApiUrl + 'smartpath/attach-org-units';
    },
    detachSmartPathOrgUnitUrl() {
      return mainApiUrl + 'smartpath/detach-org-units';
    },
    getLinkableSmartPathsUrl() {
      return mainApiUrl + 'smartpath/get-linkable-smartpaths';
    },
    linkSmartPathTransactionUrl() {
      return mainApiUrl + 'smartpath/link-smartpath-transaction';
    },
    saveTransactionTriageMessageUrl() {
      return mainApiUrl + 'smartpath/save-transaction-triage-message';
    },
    saveTransactionMessageForTriageUrl() {
      return mainApiUrl + 'smartpath/save-transaction-message-for-triage';
    },
    getOrgUnitUsersOptedIntoSmartPathUrl() {
      return mainApiUrl + 'smartpath/get-org-unit-users-opted-into-smartpath/';
    },
    // end SmartPath

    getZonesByProvinceUrl(province_id) {
      return mainApiUrl + 'get-zones-by-province/' + province_id;
    },
    recordLearnMoreUrl() {
      return mainApiUrl + 'smartpath/record-learn-more';
    },
    uploadLabFormUrl() {
      return mainApiUrl + 'smartpath/upload-lab-form';
    },
    uploadForYourPatientFilesUrl() {
      return mainApiUrl + 'smartpath/upload-for-your-patient-files';
    },
    previewSmartPathLabFormUrl() {
      return mainApiUrl + 'smartpath/preview-lab-form';
    },
    downloadSmartPathForYourPatientFileUrl() {
      return mainApiUrl + 'smartpath/download-for-your-patient-file';
    },
    removeSmartPathLabFormUrl() {
      return mainApiUrl + 'smartpath/remove-lab-form';
    },
    removeSmartPathForYourPatientFileUrl() {
      return mainApiUrl + 'smartpath/remove-for-your-patient-file';
    },
    createOrUpdateThankYouUrl() {
      return mainApiUrl + 'thank-you';
    },
    likeThankYouUrl() {
      return mainApiUrl + 'like-thank-you';
    },
    sendNotificationToUserFromTextMessageUrl(code) {
      return mainApiUrl + `notify-user-from-text-message/${code}`;
    },

    // ACS Service APIs
    getAcsAccessTokenUrl() {
      return mainApiUrl + 'acs/get-access-token';
    },
    acsSaveCallIdUrl() {
      return mainApiUrl + 'acs/save-call-id';
    },
    getCallRecordsByUserIdUrl() {
      return mainApiUrl + 'call-record/get-by-user-id';
    },
    saveCallNetworkDiagnosticUrl() {
      return mainApiUrl + 'save-call-network-diagnostic';
    },
    saveCallMediaDiagnosticUrl() {
      return mainApiUrl + 'save-call-media-diagnostic';
    },
    saveMediaStatisticsReportUrl() {
      return mainApiUrl + 'save-media-statistics-report';
    },
    saveDeviceVoipTokenUrl() {
      return mainApiUrl + 'user/save-device-voip-token';
    },

    requestEmailUpdateUrl() {
      return mainApiUrl + `user/request-email-update`;
    },
    requestContactEmailVerificationUrl() {
      return mainApiUrl + `user/request-contact-email-verification`;
    },
    verifyOtpForContactEmailVerificationUrl() {
      return mainApiUrl + `user/verify-contact-email`;
    },
    verifyEmailUpdateUrl() {
      return mainApiUrl + `user/verify-email-update`;
    },

    getCallDisplayNamesUrl() {
      return mainApiUrl + `call-display-name`;
    },
    createOrUpdateDisplayNameUrl() {
      return mainApiUrl + `create-call-display-name`;
    },
    deleteDisplayNameUrl() {
      return mainApiUrl + `delete-call-display-name`;
    },

    callerBetaRequestAccessUrl() {
      return mainApiUrl + `request-caller-beta-access`;
    },
    getSummariesByReferralUrl() {
      return mainApiUrl + 'scribe/get-summaries-by-referral';
    },
    regenerateSummaryUrl() {
      return mainApiUrl + 'scribe/regenerate-summary';
    },
    getScribeStatusUrl() {
      return mainApiUrl + 'scribe/get-status';
    },

    getAllInteractionTypes() {
      return mainApiUrl + `interaction-types`;
    },
    downloadAttachedFileByIdUrl() {
      return mainApiUrl + `attached-file/download-by-id`;
    },

    getPoemUrl() {
      return mainApiUrl + `poem`;
    },
    getPoemsUrl() {
      return mainApiUrl + `poems/pagination`;
    },
    getPoemTagsUrl() {
      return mainApiUrl + `poem/tags`;
    },
    userCommentUrl() {
      return mainApiUrl + `comments`;
    },
    reportUserCommentUrl() {
      return mainApiUrl + `comments/report`;
    },
    togglePoemBookmarkUrl() {
      return mainApiUrl + `poem/bookmark`;
    },
    togglePoemLikeUrl() {
      return mainApiUrl + `poem/like`;
    },
    subscribeToKlaviyoListUrl() {
      return mainApiUrl + `klaviyo/subscribe`;
    },
    createUserAnalyticUrl() {
      return mainApiUrl + 'user-analytic/create';
    },

    getConnectProgramInviteCodeUrl() {
      return mainApiUrl + 'connect-program/get-invite-code';
    },
    sendConnectProgramEmailInvitesUrl() {
      return mainApiUrl + 'connect-program/send-email-invites';
    },
    getRewardPointsByUserUrl() {
      return mainApiUrl + 'connect-program/get-reward-points-by-user';
    },
    claimRewardUrl() {
      return mainApiUrl + 'connect-program/claim-reward';
    },

    // Accuro APIs
    getAccuroApiAccessTokenUrl() {
      return mainApiUrl + 'external-referral-request/accuro/get-access-token';
    },
    searchAccuroEmrPatientUrl() {
      return mainApiUrl + 'external-referral-request/search-patient';
    },
    downloadAccuroRepliFormUrl() {
      return mainApiUrl + 'external-referral-request/accuro/download-repliform';
    },
    getOauthExternalClientsUrl() {
      return mainApiUrl + 'oauth-external-clients';
    },

    // Courses
    getCourseTopicsUrl() {
      return mainApiUrl + 'courses/get-course-topics';
    },
    getCourseTopicUrl() {
      return mainApiUrl + 'courses/get-course-topic-by-id';
    },
    getCourseUrl() {
      return mainApiUrl + 'courses/get-course-by-id';
    },
    getCourseModuleUrl() {
      return mainApiUrl + 'courses/get-course-module-by-id';
    },
    getCourseAssessmentUrl() {
      return mainApiUrl + 'courses/course-assessment';
    },
    getCreateCourseModuleUserContentInteractionUrl() {
      return mainApiUrl + 'courses/course-module/content-interaction';
    },
    getSubmitCourseTopicAccessCodeUrl() {
      return mainApiUrl + 'courses/submit-course-topic-access-code';
    },
    getLastAssessmentAttemptUrl() {
      return mainApiUrl + 'courses/last-assessment-attempt';
    },
    downloadCmeCertificatesUrl() {
      return mainApiUrl + 'cme-certificate/download-certificates';
    },
    getUserFaxesByReferralId(referralId) {
      return mainApiUrl + `referral/${referralId}/faxes`;
    },
  },
};

export default globals;
