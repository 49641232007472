<template>
  <div v-show="visible" v-if="cmeFormAvailable" id="feedback-form-container" class="w-full" ref="feedbackForm">
    <form v-show="!lectureCreditFormSubmitted" @submit.prevent="submitFeedback" class="border-2 border-white border-solid rounded-lg w-full">
      <!-- <Message severity="info" :closable="false" class="text-left">Please complete the feedback form below by rating each question between 1-Strongly Disagree and 5-Strongly Agree. After clicking "Submit Feedback", we will email you documentation that you can submit for CME accreditation.</Message> -->

      <!-- Step Progress -->
      <div class="flex justify-center mb-4 w-full">
        <div class="flex items-center w-full">
          <template v-for="(step, index) in steps" :key="index">
            <div class="w-full h-2 flex items-center justify-center rounded-sm" :class="[currentStep > index ? 'bg-blue-500 text-white' : currentStep === index ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600']">
              <!-- {{ index + 1 }} -->
            </div>
            <div v-if="index < steps.length - 1" class="h-1 w-12" :class="[currentStep > index ? 'bg-white' : 'bg-white']"></div>
          </template>
        </div>
      </div>

      <!-- Page 1 -->
      <div v-show="currentStep === 0">
        <div class="grid grid-cols-1 gap-x-4 gap-y-2">
          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.selectedOption }">Did you perceive any degree of bias in any part of the program?<span class="text-red-500">*</span></p>
            <div class="flex flex-row text-xs">
              <div class="flex items-center">
                <RadioButton v-model="selectedOption" inputId="bias1" ariaLabel="No" name="No" value="No" />
                <label for="bias1" class="ml-2">No</label>
              </div>
              <div class="flex ml-4 items-center">
                <RadioButton v-model="selectedOption" inputId="bias2" ariaLabel="Yes" name="Yes" value="Yes" />
                <label for="bias2" class="ml-2">Yes</label>
              </div>
            </div>
          </div>
          <div v-if="selectedOption === 'Yes'" class="flex flex-row w-full" :class="{ 'text-red-500': formErrors.reasonForBias }">
            <TextArea v-model="reasonForBias" class="auth-input w-full" style="margin-top: 1rem; margin-bottom: 1rem" :autoResize="true" rows="5" cols="30" required maxLength="65535" placeholder="Please explain the biases you experienced" />
            <small class="text-red-500" v-if="formErrors.reasonForBias"> Please provide an explanation </small>
          </div>

          <div class="flex flex-col mb-2">
            <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.selectedConflictOption }">Were the conflicts of interest clearly stated by the presenter?<span class="text-red-500">*</span></p>
            <div class="flex flex-row text-xs">
              <div class="flex items-center">
                <RadioButton v-model="selectedConflictOption" inputId="conflict1" ariaLabel="No" name="No" value="No" />
                <label for="conflict1" class="ml-2">No</label>
              </div>
              <div class="flex ml-4 items-center">
                <RadioButton v-model="selectedConflictOption" inputId="conflict2" ariaLabel="Yes" name="Yes" value="Yes" />
                <label for="conflict2" class="ml-2">Yes</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Page 2 - Program Rating Part 1 -->
      <div v-show="currentStep === 1">
        <div v-for="(question, index) in ratingQuestions.slice(0, 4)" :key="index" class="flex flex-col mb-4">
          <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.ratingValues && formErrors.ratingValues[index] }">{{ question.text }}<span class="text-red-500">*</span></p>
          <Rating v-model="ratingValues[index]" :modelValue="5" :cancel="false" />
        </div>
      </div>

      <!-- Page 2 - Program Rating Part 2 -->
      <div v-show="currentStep === 2">
        <div v-for="(question, index) in ratingQuestions.slice(4)" :key="index + 4" class="flex flex-col mb-4">
          <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.ratingValues && formErrors.ratingValues[index + 4] }">{{ question.text }}<span class="text-red-500">*</span></p>
          <Rating v-model="ratingValues[index + 4]" :modelValue="5" :cancel="false" />
        </div>
      </div>

      <!-- Page 3 -->
      <div v-show="currentStep === 3">
        <div class="flex flex-col mb-4">
          <p class="mb-3 text-xs font-semibold">Additional Feedback (Optional)</p>
          <TextArea v-model="additionalFeedback" class="auth-input w-full" :autoResize="true" rows="5" cols="30" maxLength="65535" placeholder="Please provide any additional feedback or suggestions for future programs" />
        </div>

        <div class="flex flex-col mb-4">
          <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.selectedSkillChangeOption }">How would you rate the change in your knowledge/competence/skills after completing this program?<span class="text-red-500">*</span></p>
          <div class="flex flex-col gap-y-2 text-xs">
            <div v-for="option in skillChangeOptions" :key="option.value" class="flex items-center">
              <RadioButton v-model="selectedSkillChangeOption" :inputId="option.id" :ariaLabel="option.label" :name="option.value" :value="option.value" />
              <label :for="option.id" class="ml-2">{{ option.label }}</label>
            </div>
          </div>
        </div>

        <div class="flex flex-col mb-4">
          <p class="mb-3 text-xs font-semibold" :class="{ 'text-red-500': formErrors.selectedSafeLearningOption }">The speakers and/or facilitators created a safe, accessible, and productive learning environment?<span class="text-red-500">*</span></p>
          <div class="flex flex-col gap-y-2 text-xs">
            <div class="flex items-center">
              <RadioButton v-model="selectedSafeLearningOption" inputId="safeLearning1" value="Yes" />
              <label for="safeLearning1" class="ml-2">Yes</label>
            </div>
            <div class="flex items-center">
              <RadioButton v-model="selectedSafeLearningOption" inputId="safeLearning2" value="No" />
              <label for="safeLearning2" class="ml-2">No</label>
            </div>
          </div>
        </div>

        <div v-if="selectedSafeLearningOption === 'No'" class="flex flex-row w-full">
          <TextArea v-model="selectedSafeLearningReason" class="auth-input w-full" :autoResize="true" rows="5" cols="30" required maxLength="65535" placeholder="Please explain why you feel this way" />
          <small class="text-red-500" v-if="formErrors.selectedSafeLearningReason"> Please provide an explanation </small>
        </div>
      </div>

      <!-- Page 4 -->
      <div v-show="currentStep === 4">
        <div class="flex flex-col mb-2">
          <p class="mb-3 text-xs font-semibold">Please indicate your primary practice setting (optional):</p>
          <div class="flex flex-col gap-y-2 text-xs">
            <div v-for="option in practiceSettingOptions" :key="option.value" class="flex items-center">
              <RadioButton v-model="selectedPracticeSettingOption" :inputId="option.id" :ariaLabel="option.label" :name="option.value" :value="option.value" />
              <label :for="option.id" class="ml-2">{{ option.label }}</label>
            </div>
          </div>
        </div>

        <div v-if="selectedPracticeSettingOption === 'Other'" class="flex flex-row w-full">
          <TextArea v-model="selectedPracticeSettingOther" class="auth-input w-full" :autoResize="true" rows="3" cols="30" maxLength="65535" placeholder="Please specify" />
        </div>
      </div>

      <!-- Page 5 -->
      <div v-show="currentStep === 5">
        <div class="flex flex-col mb-2">
          <p class="mb-3 text-xs font-semibold">Please indicate if you self-identify with any of the following (optional, select all that apply):</p>
          <div class="flex flex-col gap-y-2 text-xs">
            <div v-for="option in selfIdentificationOptions" :key="option.value" class="flex items-center">
              <Checkbox v-model="selectedSelfIdentificationOption" :inputId="option.id" :value="option.value" />
              <label :for="option.id" class="ml-2">{{ option.label }}</label>
            </div>
          </div>
        </div>

        <div v-if="showSelfIdentificationOther" class="flex flex-row w-full">
          <TextArea v-model="selectedSelfIdentificationOther" class="auth-input w-full" :autoResize="true" rows="3" cols="30" maxLength="65535" placeholder="Please specify if comfortable" />
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="flex justify-between mt-6">
        <Button v-if="currentStep > 0" type="button" label="Previous" icon="pi pi-chevron-left" class="p-button-secondary" @click="previousStep" />
        <div class="flex-grow"></div>
        <Button v-if="currentStep < steps.length - 1" type="button" :label="currentStep === 4 ? 'Skip' : 'Next'" icon="pi pi-chevron-right" iconPos="right" class="p-button" @click="nextStep" />
        <Button v-else type="submit" label="Submit Feedback" icon="pi pi-check" class="p-button" />
      </div>
    </form>

    <div v-show="lectureCreditFormSubmitted" class="flex flex-col items-center justify-center text-center px-16 py-24 border-2 border-gray-200 border-solid rounded-lg">
      <i class="mt-2 mb-6 fas fa-award text-blue" style="font-size: 2.5rem"></i>
      <h2 class="mb-6 text-2xl font-bold text-blue">CME Credit Sent</h2>
      <p class="mb-8 text-sm">
        Your credit certification has been emailed to <strong>{{ loggedInUser.email }}</strong>
      </p>
      <div class="grid grid-cols-1">
        <Button label="More CME Lectures" class="p-button-secondary" @click="$router.push('/lectures')" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import TextArea from 'primevue/textarea';
import Message from 'primevue/message';
import { mapActions } from 'vuex';

export default {
  name: 'CmeLectureCreditForm',
  components: {
    Button,
    Rating,
    RadioButton,
    TextArea,
    Message,
    Checkbox,
  },
  props: {
    loggedInUser: {
      type: Object,
      required: true,
    },
    lecture: {
      type: Object,
      required: true,
    },
    cmeFormAvailable: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 0,
      steps: ['Bias Assessment', 'Program Rating - Part 1', 'Program Rating - Part 2', 'Learning Environment', 'Practice Setting', 'Demographics'],
      ratingQuestions: [{ text: 'Did the session/program meet the stated objectives?' }, { text: 'Will the info learned be useful in your practice?' }, { text: 'Did the content meet your expectations?' }, { text: 'Were there adequate opportunities to interact with peers?' }, { text: 'Will the information learned be useful in your practice?' }, { text: 'I feel confident in my ability to achieve the learning objectives of this program' }, { text: 'The format and design of the learning activities were effective in enhancing my learning.' }, { text: 'The facilitators were effective for delivering the content and engaging participants.' }],
      ratingValues: [0, 0, 0, 0, 0, 0, 0, 0],
      selectedOption: '',
      selectedConflictOption: '',
      selectedSkillChangeOption: '',
      selectedSafeLearningOption: '',
      selectedSafeLearningReason: '',
      reasonForBias: '',
      selectedPracticeSettingOption: '',
      selectedPracticeSettingOther: '',
      selectedSelfIdentificationOption: [],
      selectedSelfIdentificationOther: '',
      additionalFeedback: '',
      lectureCreditFormSubmitted: false,
      formErrors: {},

      skillChangeOptions: [
        { id: 'skill1', value: 'No Change', label: 'I was reassured that I was already doing the right thing' },
        { id: 'skill2', value: 'Slight Improvement', label: 'Slight Improvement' },
        { id: 'skill3', value: 'Moderate Improvement', label: 'Moderate Improvement' },
        { id: 'skill4', value: 'Significant Improvement', label: 'Significant Improvement' },
        { id: 'skill5', value: 'N/A', label: 'N/A' },
      ],

      practiceSettingOptions: [
        { id: 'practiceSetting1', value: 'Urban', label: 'Urban' },
        { id: 'practiceSetting2', value: 'Suburban', label: 'Suburban' },
        { id: 'practiceSetting3', value: 'Rural', label: 'Rural' },
        { id: 'practiceSetting4', value: 'Remote', label: 'Remote' },
        { id: 'practiceSetting5', value: 'Prefer not to answer', label: 'Prefer not to answer' },
        { id: 'practiceSetting6', value: 'Other', label: 'Other' },
      ],

      selfIdentificationOptions: [
        { id: 'identity1', value: 'Indigenous', label: 'Indigenous' },
        { id: 'identity2', value: 'Racialized person', label: 'Racialized person (please specify if comfortable)' },
        { id: 'identity3', value: '2SLGBTQ+', label: '2SLGBTQ+' },
        { id: 'identity4', value: 'Person with a disability', label: 'Person with a disability' },
        { id: 'identity5', value: 'Non-binary/Gender non-conforming', label: 'Non-binary/Gender non-conforming' },
        { id: 'identity6', value: 'Immigrant/newcomer', label: 'Immigrant/newcomer' },
        { id: 'identity7', value: 'Prefer not to answer', label: 'Prefer not to answer' },
        { id: 'identity8', value: 'Other', label: 'Other' },
      ],
    };
  },
  computed: {
    showSelfIdentificationOther() {
      return this.selectedSelfIdentificationOption.includes('Racialized person') || this.selectedSelfIdentificationOption.includes('Other');
    },
  },
  inject: ['mixpanel'],
  watch: {
    // Page 1 Auto Next
    selectedOption(newValue) {
      if (this.currentStep === 0 && newValue === 'No' && this.selectedConflictOption === 'No') {
        this.nextStep();
      }
    },
    selectedConflictOption(newValue) {
      if (this.currentStep === 0 && newValue === 'No' && this.selectedOption === 'No') {
        this.nextStep();
      }
    },
    // Page 2 + 3 Auto Next
    ratingValues: {
      handler(newValue) {
        if (this.currentStep === 1 && newValue.slice(0, 4).every((value) => value !== 0)) {
          this.nextStep();
        }
        if (this.currentStep === 2 && newValue.slice(4).every((value) => value !== 0)) {
          this.nextStep();
        }
      },
      deep: true,
    },
    // Page 4 Auto Next
    selectedSkillChangeOption(newValue) {
      if (this.currentStep === 3 && newValue && this.selectedSafeLearningOption) {
        if (this.selectedSafeLearningOption === 'No' && !this.selectedSafeLearningReason.trim()) {
          return;
        }
        this.nextStep();
      }
    },
    selectedSafeLearningOption(newValue) {
      if (this.currentStep === 3 && newValue && this.selectedSkillChangeOption) {
        if (newValue === 'No' && !this.selectedSafeLearningReason.trim()) {
          return;
        }
        this.nextStep();
      }
    },
    selectedSafeLearningReason(newValue) {
      // Remove auto-progression on text input
      // The user will need to click the Next button manually
      return;
    },
    selectedPracticeSettingOption(newValue) {
      if (this.currentStep === 4 && newValue && newValue !== 'Other') {
        this.nextStep();
      }
    },
  },
  methods: {
    ...mapActions(['createCmeCredit']),
    validateCurrentStep() {
      this.formErrors = {};
      let isValid = true;

      switch (this.currentStep) {
        case 0:
          if (!this.selectedOption) {
            this.formErrors.selectedOption = true;
            isValid = false;
          }
          if (!this.selectedConflictOption) {
            this.formErrors.selectedConflictOption = true;
            isValid = false;
          }
          if (this.selectedOption === 'Yes' && !this.reasonForBias.trim()) {
            this.formErrors.reasonForBias = true;
            isValid = false;
          }
          break;

        case 1:
          this.formErrors.ratingValues = [];
          for (let i = 0; i < 4; i++) {
            if (!this.ratingValues[i]) {
              this.formErrors.ratingValues[i] = true;
              isValid = false;
            }
          }
          break;

        case 2:
          this.formErrors.ratingValues = [];
          for (let i = 4; i < this.ratingValues.length; i++) {
            if (!this.ratingValues[i]) {
              this.formErrors.ratingValues[i] = true;
              isValid = false;
            }
          }
          break;

        case 3:
          if (!this.selectedSkillChangeOption) {
            this.formErrors.selectedSkillChangeOption = true;
            isValid = false;
          }
          if (!this.selectedSafeLearningOption) {
            this.formErrors.selectedSafeLearningOption = true;
            isValid = false;
          }
          if (this.selectedSafeLearningOption === 'No' && !this.selectedSafeLearningReason.trim()) {
            this.formErrors.selectedSafeLearningReason = true;
            isValid = false;
          }
          break;

        case 4:
          isValid = true;
          break;
      }

      return isValid;
    },
    nextStep() {
      if (this.validateCurrentStep()) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    submitFeedback() {
      if (!this.validateCurrentStep()) return;

      const feedback_form = JSON.stringify({
        q1: this.ratingValues[0],
        q2: this.ratingValues[1],
        q3: this.ratingValues[2],
        q4: this.ratingValues[3],
        q5: this.ratingValues[4],
        q6: this.ratingValues[5],
        q7: this.ratingValues[6],
        q8: this.ratingValues[7],
        bias: this.selectedOption,
        conflicts_of_interest: this.selectedConflictOption,
        skill_change: this.selectedSkillChangeOption,
        safe_learning: this.selectedSafeLearningOption,
        practice_setting: this.selectedPracticeSettingOption,
        self_identification: this.selectedSelfIdentificationOption,
        bias_reason: this.reasonForBias,
        safe_learning_reason: this.selectedSafeLearningReason,
        practice_setting_other: this.selectedPracticeSettingOther,
        self_identification_other: this.selectedSelfIdentificationOther,
        additional_feedback: this.additionalFeedback,
      });

      this.createCmeCredit({
        feedback_form: feedback_form,
        user_id: this.loggedInUser.id,
        creditable_id: this.lecture.id,
        creditable_type: 'App\\Models\\Lecture',
      })
        .then(() => {
          this.lectureCreditFormSubmitted = true;
          this.scrollToFeedbackForm();
          this.$emit('feedback-submitted');
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Submitted',
            detail: 'Your CME credit has been emailed to you for download',
            life: 5000,
          });
          this.mixpanel.track('CME - Clicked Submit Feedback Button', {
            lectureTitle: this.lecture.lecture_title,
            presenterName: this.lecture.presenter_name,
            cmeCreditAvailable: this.lecture.cme_credit_available,
            isUpcomingLecture: this.lecture.isUpcomingLecture,
            cmeCreditAmount: this.lecture.cme_credit_amount,
          });
        })
        .catch(() => {
          this.$emit('error');
        });
    },
    scrollToFeedbackForm() {
      const feedbackForm = this.$refs.feedbackForm;
      feedbackForm.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.p-checkbox-box {
  border-radius: 0.25rem;
}
.p-rating-icon {
  font-size: 2.5rem;
}
</style>
