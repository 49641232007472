<template>
  <div class="main-inner-wrap" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div v-auto-animate class="flex flex-col items-center mt-6 animated fade-in-up animation-delay">
      <div class="flex flex-row justify-start w-full px-2 mb-4 text-sm font-semibold text-black cursor-pointer align-start">
        <h1 @click="$router.push('/poems')" id="singlePoemBackButton">&lt; <span class="border-b-2 border-black border-solid">Back</span></h1>
      </div>
      <article class="w-full max-w-3xl p-4 my-2 text-left bg-white border border-gray-300 shadow-sm rounded-xl md:px-12 md:py-10 text-balance">
        <div class="flex flex-col-reverse md:flex-row md:mt-0">
          <div class="text-lg font-semibold text-gray-900 sm:text-xl text-pretty">{{ selectedPoem.title }}</div>
          <div v-if="selectedPoem?.cme_credits?.length === 0 || !hasCompletedAccountSetup" class="mb-4 ml-auto md:mb-0 min-w-[132px]">
            <Button label="Claim Credit" icon="fas fa-award" class="p-button-sm p-button-secondary" @click="openClaimPoemCmeCreditDialog" id="claimCmeCreditButton" />
          </div>
        </div>
        <template v-if="!loggedInUser">
          <div class="relative flex flex-col grow">
            <div class="my-4">
              <div class="text-base font-semibold">Clinical Question</div>
              <div class="text-sm">
                {{ selectedPoem.clinical_question }}
              </div>
            </div>
            <div class="w-full h-full p-4 rounded-md bg-gray-50 blur-sm">
              <div class="h-full break-all">
                {{ selectedPoem.html }}
              </div>
            </div>
            <div class="absolute z-50 max-w-xl px-6 m-auto text-center bg-white border border-gray-500 shadow-sm top-40 rounded-xl py-9 -right-6 -left-6 sm:right-0 sm:left-0 sm:p-9 w-fit">
              <div class="max-w-lg text-black bg-white animated fade-in-up rounded-2xl">
                <h1 class="mb-3 text-xl font-bold">Sign up to view</h1>
                <p class="w-full mb-8 text-xs">To view POEMs and claim CME credits, sign up for your free account.</p>
                <div class="flex flex-col w-full max-w-lg px-2 text-center text-gray-600 text-md">
                  <RegistrationForm :loggedInUser="loggedInUser" :queryProps="{ redirectFromRegistration: `/poem/${selectedPoem.id}` }" />
                  <div class="mt-4 text-sm font-bold font-display">Already have an account? <span class="cursor-pointer text-primary" @click="redirectToLogin">Log in here</span>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="my-2" v-html="selectedPoem.html"></div>
          <div class="flex flex-row">
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemLikeOnSubmit()" :id="`poem-like-button-${selectedPoem.id}`">
              <i class="pr-3 pi" :class="selectedPoem?.likes?.length > 0 ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'"></i>
              <span class="text-sm font-base">{{ selectedPoem.likes_count }}</span>
            </div>
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemBookmarkOnSubmit()" :id="`poem-bookmark-button-${selectedPoem.id}`">
              <i class="pi" :class="selectedPoem?.bookmarks?.length > 0 ? 'pi-bookmark-fill' : 'pi-bookmark'"></i>
            </div>
            <div class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="shareOnClick($event, selectedPoem)" :id="`poem-share-button-${selectedPoem.id}`">
              <i class="pr-0 pi pi-send sm:pr-2"></i>
              <span class="hidden text-sm font-base sm:block">Share</span>
            </div>
            <div v-if="selectedPoem?.cme_credits?.length > 0" class="flex items-center justify-center px-3 py-1 mt-2 text-white flew-row rounded-xl bg-secondary">
              <i class="pr-0 text-white fas fa-award sm:pr-2" style="font-size: 0.8rem" />
              <span class="hidden text-sm font-base sm:block">Claimed</span>
            </div>
            <div v-else class="flex items-center justify-center px-3 py-1 mt-2 mr-2 border cursor-pointer flew-row rounded-xl hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="openClaimPoemCmeCreditDialog">
              <i class="pr-0 fas fa-award sm:pr-2"></i>
              <span class="hidden text-sm font-base sm:block">Claim Credit</span>
            </div>
            <div class="items-center justify-center hidden px-3 py-1 mt-2 sm:flex">
              <span class="text-sm text-black font-base text-nowrap">{{ formatDate(selectedPoem?.delivery_datetime) }}</span>
            </div>
          </div>

          <CommentsSection :commentable="selectedPoem" commentableType="App\Models\Poem" @needsAccountSetup="$refs.upgradeAccountModal.openModal()" />
        </template>
      </article>
    </div>
    <Head>
      <title>Virtual Hallway Presents - {{ selectedPoem?.title }}</title>
      <meta property="og:title" :content="`Virtual Hallway Presents - ${selectedPoem?.title}`" />
      <meta property="og:description" content="POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient." />
      <meta property="og:url" :content="`https://app.virtualhallway.ca/poem/${selectedPoem?.id}`" />
      <meta name="description" content="POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient." />
    </Head>
  </div>
  <ClaimPoemCmeCreditDialog ref="claimPoemCmeCreditDialog" />
  <Modal ref="upgradeAccountModal" class="mx-auto">
    <template v-slot:body>
      <div class="w-full px-6 py-8 m">
        <div class="flex flex-col items-center justify-center w-full px-10 py-8">
          <div class="text-xl font-bold text-secondary">Verify Your Account</div>
          <div class="mt-2 mb-8 text-gray-500">To get full access</div>
          <Button class="p-button-secondary" @click="$router.push('/account-setup')">Verify Account</Button>
        </div>
      </div>
    </template>
  </Modal>
  <PoemRegistrationFormModal ref="poemRegistrationFormModal" :selectedPoem="selectedPoem" />
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import PoemRegistrationFormModal from '@/components/poems/PoemRegistrationFormModal.vue';
import CommentsSection from '@/components/poems/CommentsSection.vue';
import { Share } from '@capacitor/share';
import Menu from 'primevue/menu';
import { Head } from '@unhead/vue/components';

export default {
  inject: ['mixpanel'],
  components: {
    Loading,
    Modal,
    RegistrationForm,
    Menu,
    Head,
    PoemRegistrationFormModal,
    CommentsSection,
  },
  data() {
    return {
      isLoading: false,
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(['selectedPoem', 'loggedInUser']),
    hasCompletedAccountSetup() {
      return this.loggedInUser?.role && this.loggedInUser?.first_name && this.loggedInUser?.last_name && (this.loggedInUser?.role !== 'cme' || (this.loggedInUser?.profession && this.loggedInUser?.address.province));
    },
  },
  methods: {
    ...mapActions(['getPoem', 'togglePoemBookmark', 'togglePoemLike', 'createUserAnalytic']),
    openClaimPoemCmeCreditDialog() {
      if (!this.loggedInUser) {
        this.$refs.poemRegistrationFormModal.openModal();
        return;
      } else if (!this.hasCompletedAccountSetup) {
        this.$refs.upgradeAccountModal.openModal();
        return;
      }
      this.$refs.claimPoemCmeCreditDialog.openDialog();
    },
    redirectToLogin() {
      this.$router.push(`/login?redirect=poem/${this.selectedPoem.id}`);
    },
    async shareOnClick(event, poem) {
      const url = `https://app.virtualhallway.ca/api/poem/view/${poem.id}`;

      if (this.isNativePlatform()) {
        try {
          await Share.share({
            title: 'Virtual Hallway POEMs',
            text: poem.title,
            url,
            dialogTitle: 'Share POEMs Link',
          });
        } catch (e) {}
      } else {
        this.setShareLinks(poem);
        this.$refs.menu.toggle(event);
      }
    },
    setShareLinks(poem) {
      const baseUrl = `https://app.virtualhallway.ca/api/poem/view/${poem.id}`;
      this.menuItems = [
        {
          label: 'Facebook',
          icon: 'pi pi-facebook',
          command: () => {
            const url = encodeURI(`https://www.facebook.com/share_channel/?link=${baseUrl}&app_id=966242223397117&source_surface=external_reshare&display&hashtag=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'LinkedIn',
          icon: 'pi pi-linkedin',
          command: () => {
            const url = encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${baseUrl}`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Twitter',
          icon: 'pi pi-twitter',
          command: () => {
            const url = encodeURI(`https://x.com/intent/post?text=${poem.title}&url=${baseUrl}&hashtags=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Copy Link',
          icon: 'pi pi-link',
          command: () => {
            this.copyToClipboard(poem);
          },
        },
      ];
    },
    copyToClipboard(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `${hostname}/poem/${poem.id}`;

      navigator.clipboard.writeText(url);
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
        life: 2000,
      });
    },
    togglePoemBookmarkOnSubmit() {
      this.togglePoemBookmark(JSON.parse(JSON.stringify(this.selectedPoem)))
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    togglePoemLikeOnSubmit() {
      this.togglePoemLike(JSON.parse(JSON.stringify(this.selectedPoem)))
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getPoem({ id: this.$route.params.id, user_analytic_meta_data: JSON.stringify({ url_query: this.$route.fullPath }) })
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });

    if (this.isIphoneBrowserButNotIosApp()) {
      window.location.href = `virtualhallway://login?redirect=${this.$route.fullPath}`;
    }
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$refs?.poemRegistrationFormModal?.closeModal();
        this.getPoem({ id: this.$route.params.id })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
  },
};
</script>

<style>
.poem-container,
.poem-container * {
  display: block;
  box-sizing: border-box;
}
.poem-container {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1f2937;
  position: relative;
  display: block;
  text-wrap: pretty;
}
.poem-container b {
  padding-top: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: black;
}
.poem-container b ~ br {
  display: none;
}
.poem-container p * {
  display: inline;
}
.poem-container p b {
  display: block;
}
.poem-container .poem_author {
  padding-top: 0.75rem;
}
.poem-container a {
  text-decoration: underline;
  color: -webkit-link;
}

.poem-container table {
  margin: 1rem 0;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
}

.poem-container table th,
.poem-container table td,
.poem-container table tr {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.poem-container table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.poem-container table td {
  display: table-cell;
  vertical-align: inherit;
  border-color: inherit;
}

.poem-container table th {
  background-color: #f7fafc;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  color: #4a5568;
}

.poem-container table tbody tr:last-child td {
  border-bottom: none;
}

.poem-container table tbody tr:hover {
  background-color: #f8fafc;
  transition: background-color 0.2s ease;
}

@media (max-width: 640px) {
  .poem-container table th,
  .poem-container table td {
    padding: 8px 12px;
  }
}

@media (max-width: 450px) {
  .poem-container table th,
  .poem-container table td {
    padding: 4px 2px;
  }
}
</style>
